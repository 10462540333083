import React, {useState, useEffect} from "react"
import Form from "../Form"
import { useModal } from "../Hooks/useModal";
import Modal from "../Modal";
import "../Modal/Modal.css"

import logo from "../../images/medical-mask-48.png"

const NewPost = ({setNewPost, ...props}) => {
    const [modalOpen, setModalOpen, toggleModal] = useModal();
    return (
        <div className="uk-container uk-container-xsmall">
            <button
                className="new-post-button uk-border-pill uk-visible@s uk-flex uk-flex-middle uk-flex-center uk-padding-small uk-box-shadow-small uk-box-shadow-hover-large"
                onClick={toggleModal}>
                <div className="uk-margin-small-right uk-margin-small-left">
                    <img src={logo} width="64" />
                </div>
                <div className="uk-margin-right">Make your Mask Meme</div>
            </button>
            <Modal
                isActive={modalOpen}
                className="fullscreen-card">
                <div className="uk-width-1-1">
                    <div className="uk-text-center">
                        <Form setNewPost={setNewPost} toggleModal={toggleModal} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default NewPost
