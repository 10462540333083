import React, {useState, useEffect} from "react";
import axios from "axios"
import { Link } from "gatsby";
import SocialShare from "../SocialShare"
import moment from "moment";
import heart from "../../images/icons/heart.svg"

async function deletePostCall(post) {
    const endpoint = `${process.env.GATSBY_ISTNOTYOU_DELETE_POST}/${post}`;
    const token = localStorage.auth
    const result = await axios.delete(endpoint, {
        headers: {
            "hypermix-token": process.env.GATSBY_ISTNOTYOU_TOKEN,
            'Authorization': `Bearer ${token}`
        }
    })
    const res = await result.data;
    return res;
}

const deletePost = post => {
    console.log("DLETE", post)
    deletePostCall(post)
    .then(response => {
        if (response.status_code === 204) {
            window.location.replace(`/`);
        }
    })
    .catch(function (error) {
        console.log("FIRST CATCH ERROR", error)
        console.log("FIRST CATCH ERROR", error.response)
    })
}

const _slugify = str => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;'";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

const AdminTools = ({post, ...props}) => {
    return (
        <div style={{backgroundColor: "#f8f8f8", padding: "4px"}} className="uk-card-header">
            <div className="uk-grid uk-grid-small uk-flex-right uk-child-width-auto uk-text-right">
                <div>
                    <a href={`/post/${post}`} data-uk-icon="icon: link" />
                </div>
                <div>
                    <span onClick={() => deletePost(post)} data-uk-icon="icon: close" />
                </div>
            </div>
        </div>
    )
}

const PostItem = ({source, post, thumb, location, isLoading, ...props}) => {
    const {country, alpha2, file, headline, message, timestamp} = post.data;
    const [authenticated, setAuthenticated] = useState(false)

    useEffect(
        () => {
            const token = localStorage.auth
            token && token.length ? setAuthenticated(true) : setAuthenticated(false)
        }, []
    )

    return (
        <div id={_slugify(`${headline} ${message}`)} className={`${thumb ? "uk-margin-medium-bottom" : "uk-margin-small-bottom"}`}>
            <div className={`uk-card uk-card-small post-${source}`}>
                {authenticated ? <AdminTools post={post.id} /> : null}
                <div className="uk-background-muted uk-card-media-top uk-margin-remove-adjacent uk-padding-remove">
                    <a key={`posts-${post.id}`} href={`/post/${post.id}`}>
                        <img
                            src={file}
                            alt={`${message}`}
                            data-uk-img
                            />
                    </a>
                </div>
                {!thumb ?
                <div style={{color: "#666", padding: "0 12px 3px", borderRadius: "0 0 4px 4px"}} className="uk-card-body">
                    <div className="uk-grid uk-grid-small uk-flex-middle" data-uk-grid>
                        <div className="uk-width-expand uk-text-truncate">
                            <small style={{fontSize: "0.75rem"}} className="uk-margin-remove-vertical">
                                <img style={{marginRight: "4px"}} src={heart} className="uk-padding-remove uk-margin-remove-vertical" width="16" data-uk-svg />
                                    from <span className="uk-text-bold">{country}</span> about <time dateTime={new Date(parseInt(timestamp))}>
                                {moment(new Date(parseInt(timestamp))).fromNow()}
                            </time>
                            </small>
                        </div>
                        <div className="uk-width-auto">
                            {!isLoading ?
                            <SocialShare data={post.data} id={post.id} file={file} />
                            : ""}
                        </div>
                    </div>
                </div>
                : null}
            </div>
        </div>
    )
}

export default PostItem
