import React, {useState, useEffect} from "react";
import ShareButtons from "./ShareButtons"
import { useModal } from "../Hooks/useModal";
import Modal from "../Modal";
import "../Modal/Modal.css"
import image from "../../images/favicons/medical-mask-144.png";
import social from "../../images/icons/social.svg";


const randomize = () => {
    return Math.floor((Math.random() * 1000) + 1);
}

const SocialShare = ({data, id, file, ...props}) => {
    const [modalOpen, setModalOpen, toggleModal] = useModal();
    // const url = checkPost ? `https://itsnotyouits.us/post/${id}` : `https://post.itsnotyouits.us/post/${id}`
    const url = `https://post.itsnotyouits.us/meta/${id}`
    return (
    <div className="uk-width-auto">
        <img style={{paddingTop: "4px"}} src={social} onClick={toggleModal} data-uk-svg />
        <Modal
            isActive={modalOpen}
            id={`sharePost${id}`}
            className="notification-card">
            <div style={{height: "100%"}} className="uk-flex uk-flex-middle uk-flex-center uk-height-1-1">
                <div style={{maxWidth: "400px", backgroundColor: "#fff"}} className="uk-card uk-card-default uk-card-body">
                    <div style={{marginTop: "-50px"}} className="uk-text-center">
                        <img
                            src={file}
                            className="uk-border-rounded"
                            height="96"
                            width="96"
                            alt="Share this post"
                            />
                    </div>
                    <h2 className="uk-margin-top uk-margin-medium-bottom uk-text-center">Share the health</h2>
                    <div>
                    <ShareButtons data={data} url={url} />
                    </div>
                    <button className="uk-button uk-button-text cta-button uk-margin-large" onClick={toggleModal}>Close</button>
                </div>
            </div>
        </Modal>
    </div>
    )
}

export default SocialShare
